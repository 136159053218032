import { GlobalContext } from "@navigation/Router";
import React, { useContext } from "react";

import { History } from "@modules/logHistory/History";

export function MyHistory() {
	const { user } = useContext(GlobalContext);

	if (!user) return;
	return (
		<History
			className=""
			currentStudent={user}
			showByDefault={true}
			filters={{
				action_list: [],
				page: 1,
			}}
		/>
	);
}
