import { useAppSelector } from "@config/redux/hook";
import Tippy from "@tippyjs/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { funnelOutline } from "@assets/Icons";

import { ToggleButtonGroupLarge } from "@designSystem/atoms/ToggleButtonGroup";

import {
	countCurrentFilters,
	performReloadOnSearch,
	reloadFiltersFromCookies,
	setCurrentSearch,
	setShowFilters,
} from "../core/store/usersFilters.slice";

export function ToggleUserFilters({
	disabled = false,
	cannotBeOpen = false,
}: {
	disabled?: boolean;
	cannotBeOpen?: boolean;
}) {
	const dispatchEvent = useDispatch();
	const { showFilters, filtersCounter } = useAppSelector(
		(state) => state.usersFilters,
	);
	const performFullReload = async () => {
		await dispatchEvent(reloadFiltersFromCookies() as any);
		dispatchEvent(countCurrentFilters());
		dispatchEvent(performReloadOnSearch());
	};
	useEffect(() => {
		performFullReload();
		dispatchEvent(countCurrentFilters());
	}, []);

	return (
		<Tippy
			content={showFilters ? "Fermer les filtres" : "Ouvrir les filtres"}
			theme="primary"
			animation="fade"
			placement="top"
			zIndex={5}
			delay={1000}
		>
			<span className="filter-container-btn w-fit-content">
				<div
					className={`w-fit-content rounded-md ${
						disabled ? "bg-primary-50" : "bg-white"
					}`}
				>
					<ToggleButtonGroupLarge
						backgroundColor={disabled ? "bg-primary-50" : "bg-white"}
						buttons={[
							{
								icon: funnelOutline,
								onClick: () => {
									if (cannotBeOpen) return;
									if (showFilters === false) {
										dispatchEvent(setShowFilters(true));
										dispatchEvent(setCurrentSearch(""));
									} else {
										dispatchEvent(setShowFilters(false));
									}
								},
							},
						]}
						selectedIndex={showFilters ? 0 : 1}
						className="hidden md:flex"
					/>
					{filtersCounter > 0 && !disabled && (
						<span className="absolute -bottom-1 -right-1 h-3 w-3 rounded-full border border-accent-1-dark bg-white" />
					)}
				</div>
			</span>
		</Tippy>
	);
}
