import type { ViewType } from "@/types/genericType";
import { useAppSelector } from "@config/redux/hook";
import { GlobalContext } from "@navigation/Router";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useOutletContext } from "react-router-dom";

import {
	countCurrentFilters,
	setShowFilters,
} from "@modules/usersFilters/core/store/usersFilters.slice";
import { success } from "@tools/Toasts";

import { EStatus } from "@/types/userTypes";
import { Head } from "./ui/Head";
import { TableView } from "./ui/TableView";

type StudentManagementContextType = {
	displayNewStudent: boolean;
	setDisplayNewStudent: Function;
	STUDENTS_DEFAULT_FILTERS: any;
};
export const StudentsManagementContext = createContext(
	{} as StudentManagementContextType,
);

export function SendMail() {
	const STUDENTS_DEFAULT_FILTERS = {
		state: "all",
		page: 1,
		spsPromotion_archived: false,
		status_list: [
			EStatus.TOPLACE,
			EStatus.PLACED,
			EStatus.BEING_SIGNED,
			EStatus.SURREND,
		],
	};

	const { user } = useContext(GlobalContext);
	const location = useLocation();
	const {
		structureView,
		setTitle,
	}: { structureView: ViewType; setTitle: Function } = useOutletContext();
	const dispatchEvent = useDispatch();
	const { currentFilters, currentSearch, useFilters } = useAppSelector(
		(state) => state.usersFilters,
	);

	const [displayNewStudent, setDisplayNewStudent] = useState<boolean>(false);

	const getStudents = async (useInitialStateOfFilters: boolean) => {
		return useInitialStateOfFilters;
	};

	useEffect(() => {
		setTitle("Envoi d'email");
		getStudents(false);

		if (location.state?.isCreateStudent) {
			success("Étudiant crée avec succès.");
		}
		if (location.state?.isDeletingStudent) {
			success("Étudiant supprimé avec succès.");
		}
	}, [currentFilters, structureView, useFilters]);

	useEffect(() => {
		if (
			user !== null &&
			currentSearch !== undefined &&
			currentSearch !== null
		) {
			getStudents(true);
		}
	}, [currentSearch]);

	useEffect(() => {
		dispatchEvent(
			setShowFilters(!(currentSearch !== "" && currentSearch !== undefined)),
		);
		dispatchEvent(countCurrentFilters());
	}, []);
	return (
		<StudentsManagementContext.Provider
			value={{
				displayNewStudent,
				setDisplayNewStudent,
				STUDENTS_DEFAULT_FILTERS,
			}}
		>
			<main className="flex flex-col gap-md">
				<Head />
				<TableView />
			</main>
		</StudentsManagementContext.Provider>
	);
}
