import type { UserType } from "@/types/userTypes";
import { GlobalContext } from "@navigation/Router";
import React, { createContext, useContext, useEffect, useState } from "react";

import { eyeOutline, minusOutline } from "@assets/Icons";

import { getCookie, setCookie } from "@tools/Cookies";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonSecondary } from "@designSystem/atoms/ButtonSecondary";
import { InlineButton } from "@designSystem/atoms/InlineButton";
import { Modal } from "@designSystem/organisms/modal/Modal";
import {
	resetUsersFilters,
	setCurrentFilters,
} from "@modules/usersFilters/core/store/usersFilters.slice";

import { useAppSelector } from "@config/redux/hook";
import { useAppDispatch } from "@config/redux/store";
import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { CellAvatarLabelSecondary } from "@designSystem/molecules/CellAvatarLabelSecondary";
import { success } from "@tools/Toasts";
import { Table } from "../../designSystem/templates/table/Table";
import { Filters } from "./SelectStudents/Filters";
import { Notifications } from "./SelectStudents/Notifications";
import { StudentsList } from "./StudentsList";
import { getSchoolCredits, viewSelection } from "./StudentsTargetingModalAPI";

type ViewType = {
	school?: string;
	campus?: string;
};

export type StudentsTargetingModalContextType = {
	selectedStudents: UserType[];
	setSelectedStudents: Function;
	sendEmail: boolean;
	setSendEmail: Function;
	sendSMS: boolean;
	setSendSMS: Function;
	structureView: ViewType;
	totalCount: number;
	setTotalCount: Function;
	setView: Function;
	SMSCredits: number;
	getStudentWithCv: boolean;
	showOptions?: boolean;
};

export const StudentsTargetingModalContext =
	createContext<StudentsTargetingModalContextType>({
		selectedStudents: [],
		setSelectedStudents: () => {},
		sendEmail: true,
		setSendEmail: () => {},
		sendSMS: true,
		setSendSMS: () => {},
		structureView: {},
		totalCount: 1,
		setTotalCount: () => {},
		setView: () => {},
		SMSCredits: 0,
		getStudentWithCv: false,
		showOptions: true,
	});

type StudentsTargetingModalType = {
	show: boolean;
	onClose: Function;
	onClick?: Function;
	structureView: {};
	label: string;
	title: string;
	buttonLabel?: string;
	share: (
		sendEmail: boolean,
		sendSMS: boolean,
		selectedStudents: UserType[],
	) => void;
	showOptions?: boolean;
	getStudentWithCv?: boolean;
};

export function StudentsTargetingModal({
	show,
	onClose,
	structureView,
	share,
	onClick,
	label,
	buttonLabel = "Partager à la sélection",
	title,
	showOptions = true,
	getStudentWithCv = false,
}: StudentsTargetingModalType) {
	const dispatchEvent = useAppDispatch();
	const { user } = useContext(GlobalContext);
	const [selectedStudents, setSelectedStudents] = useState([]);
	const [sendEmail, setSendEmail] = useState(true);
	const [sendSMS, setSendSMS] = useState(false);
	const [totalCount, setTotalCount] = useState(1);
	const [view, setView] = useState<string>("select");
	const [SMSCredits, setSMSCredits] = useState<number>(0);

	const resetModal = () => {
		setView("select");
		setSelectedStudents([]);
		dispatchEvent(resetUsersFilters());
		setSendEmail(true);
		setSendSMS(false);
	};
	const getCredits = async () => {
		if (!user) return;
		const { smsCredits }: { smsCredits?: number } = await getSchoolCredits(
			user.school,
		);

		if (smsCredits) setSMSCredits(smsCredits);
	};

	useEffect(() => {
		if (show) getCredits();
	}, [show]);

	const getButtons = () => {
		return [
			view === "select" ? (
				<InlineButton
					id="students-targeting-modal-view-selection"
					label={`Voir toute la sélection (${selectedStudents.length})`}
					icon={eyeOutline}
					iconPosition="right"
					onClick={() => {
						setView("recap");
					}}
				/>
			) : (
				<ButtonSecondary
					key={0}
					label="Modifier la sélection"
					onClick={() => {
						setView("select");
					}}
				/>
			),
			<ButtonPrimary
				key={1}
				disabled={
					selectedStudents.length === 0 ||
					(sendSMS &&
						selectedStudents.filter((student: UserType) => student.phone)
							.length > SMSCredits) ||
					(sendEmail && selectedStudents.length === 0) ||
					(!sendEmail && !sendSMS)
				}
				label={buttonLabel}
				onClick={() => {
					setCookie(
						"previousStudentsSelection",
						JSON.stringify(selectedStudents),
					);

					share(sendEmail, sendSMS, selectedStudents);
					if (onClick) onClick();
					resetModal();
					onClose();
				}}
			/>,
		];
	};

	return (
		<StudentsTargetingModalContext.Provider
			value={{
				selectedStudents,
				setSelectedStudents,
				sendEmail,
				setSendEmail,
				sendSMS,
				setSendSMS,
				structureView,
				totalCount,
				setTotalCount,
				setView,
				SMSCredits,
				getStudentWithCv,
				showOptions,
			}}
		>
			<Modal
				show={show}
				size={view === "select" ? "lg" : "md"}
				onClose={() => {
					resetModal();
					onClose();
				}}
				title={title}
				body={
					view === "select" ? (
						<div className="flex">
							<SelectStudents label={label} />
							<StudentsList />
						</div>
					) : (
						<YourSelection />
					)
				}
				buttonsRight={getButtons()}
			/>
		</StudentsTargetingModalContext.Provider>
	);
}

function SelectStudents({ label }: { label: string }) {
	const previousStudentsSelectionCookie = getCookie(
		"previousStudentsSelection",
	);
	const [previousStudentsSelection, setPreviousStudentsSelection] = useState(
		[],
	);
	const { selectedStudents, setSelectedStudents, setView, showOptions } =
		useContext(StudentsTargetingModalContext);

	useEffect(() => {
		if (previousStudentsSelectionCookie !== null) {
			setPreviousStudentsSelection(JSON.parse(previousStudentsSelectionCookie));
		}
	}, []);

	return (
		<div className="flex w-1/2 flex-col gap-sm">
			<p className="text-primary-700P">{label}</p>
			{previousStudentsSelection.length > 0 &&
				JSON.stringify(previousStudentsSelection) !==
					JSON.stringify(selectedStudents) && (
					<InlineButton
						label="Reprendre la sélection précédente"
						onClick={() => {
							setSelectedStudents(previousStudentsSelection);
							success("La sélection a bien été récupérée");
							setView("recap");
						}}
					/>
				)}
			<div className="flex flex-col gap-sm pr-xsm">
				<Filters />
				{showOptions && <Notifications />}
			</div>
		</div>
	);
}

function YourSelection() {
	const { currentFilters } = useAppSelector((state) => state.usersFilters);
	const { selectedStudents } = useContext(StudentsTargetingModalContext);
	const [load, setLoad] = useState<boolean>(true);
	const [offset, setOffset] = useState<number>(0);
	const [students, setStudents] = useState<UserType[]>([]);

	const fetchStudents = async () => {
		setLoad(true);
		const { visibleStudents }: any = await viewSelection(
			selectedStudents
				.slice(offset, offset + 20)
				.map((student: UserType) => student.id),
		);

		setStudents([...students, ...visibleStudents.collection]);
		setLoad(false);
	};

	useEffect(() => {
		fetchStudents();
	}, [offset]);

	const cellsResolver = (student: UserType) => {
		return [
			{
				children: (
					<CellAvatarLabelSecondary
						firstname={student.firstname}
						lastname={student.lastname}
						avatar={student.avatar?.filePath}
						label={`${student.lastname} ${student.firstname}`}
						sublabel={student?.spsPromotion?.name}
					/>
				),
			},
			{
				className: "group-hover:opacity-100 opacity-0 transition",
				children: <ButtonQuinary icon={eyeOutline} onClick={() => {}} />,
			},
			{
				className: "group-hover:opacity-100 opacity-0 transition",
				children: (
					<ButtonQuinary
						icon={minusOutline}
						iconColor="text-error-dark"
						onClick={() => {}}
					/>
				),
			},
		];
	};
	return (
		<div>
			<div className="h-[320px] overflow-y-auto">
				<Table
					filtersProps={{ currentFilters, dispatchEvent: setCurrentFilters }}
					headers={[
						{ label: `${selectedStudents.length} étudiants sélectionnés` },
					]}
					isLoading={load}
					rows={students.map((student: UserType) => {
						return {
							cells: cellsResolver(student),
						};
					})}
					onReachEnd={() => {
						if (selectedStudents.length > offset + 20) setOffset(offset + 20);
					}}
				/>
			</div>
		</div>
	);
}
