import type { EStatus, UserCompleteType } from "@/types/userTypes";
import type { FiltersType } from "@/types/usersFiltersTypes";
import { useAppSelector } from "@config/redux/hook";
import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
	checkMarkCircleOutline,
	closeOutline,
	downloadOutline,
	emailOutline,
	paperPlaneOutline,
} from "@assets/Icons";

import { DAYS_BETWEEN_RELAUNCH } from "@constants/CvBoard";
import { STUDENT_STATUS_OPTIONS } from "@constants/Users";

import { convertDateFromNow, diffDate } from "@tools/Dates";
import { getStatusFromName } from "@tools/Users";

import { ButtonPrimary } from "@designSystem/atoms/ButtonPrimary";
import { ButtonQuinary } from "@designSystem/atoms/ButtonQuinary";
import { ButtonTertiary } from "@designSystem/atoms/ButtonTertiary";
import { Checkbox } from "@designSystem/atoms/Checkbox";
import { Spinner } from "@designSystem/atoms/Spinner";
import { CellAvatarLabelSecondary } from "@designSystem/molecules/CellAvatarLabelSecondary";
import { PaginationPrimary } from "@designSystem/molecules/PaginationPrimary";
import { ToastCustom } from "@designSystem/organisms/toast/Toast";
import { Table } from "@designSystem/templates/table/Table";
import { setCurrentFilters } from "@modules/usersFilters/core/store/usersFilters.slice";
import {
	errorToast,
	success,
	updateToastWithError,
	updateToastWithSuccess,
} from "@tools/Toasts";

import {
	exportCVs,
	relaunchCVStudent,
	relaunchCvsMultipleStudents,
} from "@containers/school/CVBoard/CVBoardAPI";

import { CVBoardContext } from "../..";

import { Badge } from "@designSystem/atoms/Badge";
import { CreateSelectionModal } from "./TableView/CreateSelectionModal";
import { RefusalModal } from "./TableView/RefusalModal";

export const convertCVStateToLabel = (state: string) => {
	switch (state) {
		case "validated":
			return { label: "Validé", color: "bg-success-dark" };
		case "toValidate":
			return { label: "A valider", color: "bg-error-dark" };
		default:
			return { label: "En modification", color: "bg-warning-dark" };
	}
};

export const convertStatusToLabel = (statusName: EStatus) => {
	const status = getStatusFromName(statusName);

	return (
		<Badge
			hasBorder={true}
			leftIcon={status.icon}
			label={status.label}
			bgColor={status.backgroundColor}
			textColor={status.textColor || undefined}
			borderColor={status.borderColor || undefined}
		/>
	);
};
type TableViewType = {
	setStudentInModal: Function;
	studentInModal: UserCompleteType | null;
	setShowCVModal: (showCVModal: boolean) => void;
	selectedCVs: UserCompleteType[];
	setSelectedCVs: Function;
	handleOnCheckCV: (student: UserCompleteType) => void;
	handleOnCheckAll: (checked: boolean) => void;
	showShareCVModal: boolean;
	setShowShareCVModal: (showShareCVModal: boolean) => void;
	showRefusalModal: boolean;
	setShowRefusalModal: (showRefusalModal: boolean) => void;
	checkAllLoader: boolean;
};
export function TableView({
	setStudentInModal,
	studentInModal,
	setShowCVModal,
	selectedCVs,
	setSelectedCVs,
	handleOnCheckCV,
	handleOnCheckAll,
	showShareCVModal,
	setShowShareCVModal,
	showRefusalModal,
	setShowRefusalModal,
	checkAllLoader,
}: TableViewType) {
	const dispatchEvent = useDispatch();
	const { currentFilters } = useAppSelector((state) => state.usersFilters);
	const { CVs, load, lastPage, totalCount } = useContext(CVBoardContext);

	const [downloadingCVs, setDownloadingCVs] = useState(false);

	const headers = [
		{
			content: checkAllLoader ? (
				<Spinner size="small" />
			) : (
				<Checkbox
					checked={selectedCVs.length === totalCount}
					onEnabled={() => {
						handleOnCheckAll(true);
					}}
					onDisabled={() => {
						handleOnCheckAll(false);
					}}
				/>
			),
			tabs: ["all", "editing", "toValidate", "validated", "without"],
		},
		{
			label: "Étudiants",
			sort: { fieldName: "lastname", defaultOrder: "ASC" },
			tabs: ["all", "editing", "toValidate", "validated", "without"],
		},
		{
			label: "",
			tabs: ["validated"],
		},
		{
			label: "LOCALISATION",
			sort: { fieldName: "postalCode", defaultOrder: "ASC" },
			tabs: ["all", "validated"],
		},
		{
			label: "Date de dépot",
			sort: { fieldName: "cv_createdAt", defaultOrder: "ASC" },
			tabs: ["toValidate"],
		},
		{
			label: "Statut",
			sort: { fieldName: "status", defaultOrder: "ASC" },
			tabs: ["all", "validated", "without"],
		},
		{
			label: "CV",
			tabs: ["all", "toValidate", "validated"],
		},
		{
			label: "État CV",
			sort: { fieldName: "cv_state", defaultOrder: "ASC" },
			tabs: ["all"],
		},
		{
			label: "Ancien CV",
			tabs: ["editing"],
		},
		{
			label: "Date refus",
			sort: { fieldName: "cv_updatedAt", defaultOrder: "ASC" },
			tabs: ["editing"],
		},
		{
			label: "Der. Connexion",
			sort: { fieldName: "lastConnexion", defaultOrder: "ASC" },
			tabs: ["editing", "without"],
		},
		{
			label: "Relancer",
			tabs: ["editing", "without"],
		},
		{
			label: "Der. relance",
			sort: { fieldName: "cvRelaunchDate", defaultOrder: "ASC" },
			tabs: ["editing", "without"],
		},
	].filter(
		(header) =>
			currentFilters.state && header.tabs.includes(currentFilters.state),
	);

	const handleOnRelaunchStudent = async (studentId: string) => {
		await relaunchCVStudent(studentId);
		success("Étudiant relancé avec succès. Il va recevoir un mail.");
		dispatchEvent(setCurrentFilters({ ...currentFilters }));
	};

	const cellsResolver = (student: UserCompleteType) => {
		const { status, cv } = student;
		return [
			{
				onClick: null,
				children: (
					<>
						{(currentFilters.state === "editing" ||
							currentFilters.state === "without") &&
						diffDate(new Date(student.cvRelaunchDate)) <
							DAYS_BETWEEN_RELAUNCH ? (
							<Icon
								className="text-primary-300"
								icon={checkMarkCircleOutline}
							/>
						) : (
							<Checkbox
								checked={
									selectedCVs.filter((e) => e.id === student.id).length > 0
								}
								disabled={
									(currentFilters.state === "editing" ||
										currentFilters.state === "without") &&
									diffDate(student.cvRelaunchDate) === 0
								}
								onEnabled={() => {
									handleOnCheckCV(student);
								}}
								onDisabled={() => {
									handleOnCheckCV(student);
								}}
							/>
						)}
					</>
				),

				tabs: ["all", "validated", "toValidate", "editing", "without"],
			},
			{
				children: (
					<CellAvatarLabelSecondary
						className={`${
							currentFilters.state === "without" ? "!cursor-default" : ""
						}`}
						label={`${student.lastname} ${student.firstname}`}
						sublabel={student.promotion.name}
						firstname={student.firstname}
						lastname={student.lastname}
						avatar={student.avatar?.filePath}
						avatarColor={`${
							STUDENT_STATUS_OPTIONS.find(
								(option) => option.value === student.status,
							)?.backgroundColor
						}`}
					/>
				),

				tabs: ["all", "validated", "toValidate", "editing", "without"],
			},

			{
				tabs: ["validated"],
			},
			{
				children: `${
					!student.postalCode && !student.city
						? "Non renseigné"
						: student.postalCode ?? ""
				} ${
					(student.postalCode && student.city) || student.city
						? student.city
						: ""
				}`,
				tabs: ["all", "validated"],
			},
			{
				children: convertStatusToLabel(status),

				tabs: ["all", "without", "validated"],
			},
			{
				children: convertDateFromNow(cv?.createdAt ?? ""),

				tabs: ["toValidate"],
			},
			{
				onClick: null,
				children: (
					<div className="flex">
						<ButtonTertiary
							onClick={() => {
								window.open(
									`${import.meta.env.VITE_S3}${cv?.filePath}`,
									"_blank",
								);
							}}
							icon={downloadOutline}
						/>
					</div>
				),

				tabs: ["all", "editing", "toValidate", "validated"],
			},
			{
				children: (
					<div className="flex items-center gap-sm">
						<div
							className={`h-4 w-4 rounded-full ${
								convertCVStateToLabel(cv?.state ?? "").color
							}`}
						/>
						<p>{convertCVStateToLabel(cv?.state ?? "").label}</p>
					</div>
				),

				tabs: ["all"],
			},
			{
				children: `${
					cv?.updatedAt
						? convertDateFromNow(new Date(cv?.updatedAt))
						: "Inconnue"
				}`,

				tabs: ["editing"],
			},
			{
				children: `${
					student.lastConnexion
						? convertDateFromNow(student.lastConnexion)
						: "Jamais connecté"
				}`,

				tabs: ["without", "editing"],
			},
			{
				onClick: () => {
					handleOnRelaunchStudent(student.id.split("/")[3]);
				},
				children: (
					<ButtonPrimary
						disabled={
							diffDate(new Date(student.cvRelaunchDate)) < DAYS_BETWEEN_RELAUNCH
						}
						icon={
							diffDate(new Date(student.cvRelaunchDate)) < DAYS_BETWEEN_RELAUNCH
								? checkMarkCircleOutline
								: emailOutline
						}
					/>
				),
				tabs: ["editing", "without"],
			},
			{
				children: `${
					student.cvRelaunchDate
						? convertDateFromNow(student.cvRelaunchDate)
						: "Jamais relancé"
				}`,

				tabs: ["editing", "without"],
			},
		].filter(
			(column) =>
				currentFilters.state && column.tabs.includes(currentFilters.state),
		);
	};

	const relaunchStudents = async () => {
		const studentsSelected: { id: string }[] = [];
		selectedCVs.map((student) =>
			studentsSelected.push({
				id: student?.id,
			}),
		);
		const response = await relaunchCvsMultipleStudents(studentsSelected);
		if (response === 200) {
			success("Les étudiants vont être relancés dans les prochaines minutes.");
			dispatchEvent(setCurrentFilters({ ...currentFilters }));
			setSelectedCVs([]);
			return;
		}
		errorToast("Erreur lors de l’envoi des relances.");
	};

	const downloadZip = async () => {
		if (downloadingCVs) return;
		setDownloadingCVs(true);
		const id = toast.loading("CV en cours de téléchargement.", {
			isLoading: true,
			hideProgressBar: false,
			type: "default",
		});
		const cvs = selectedCVs.map((student) => student.cv?.id);
		const { fileName } = (await exportCVs(cvs)) as { fileName: string };
		if (fileName) {
			updateToastWithSuccess(id, "CV téléchargés avec succès.");
			window.open(
				`${import.meta.env.VITE_BACK}/zip_export/${fileName}`,
				"_blank",
			);
			setSelectedCVs([]);
			setDownloadingCVs(false);
			return;
		}
		updateToastWithError(id, "Erreur lors du téléchargement des CV.");
		setDownloadingCVs(false);
	};

	const getEmptyMessage = (state: string | undefined) => {
		if (CVs && CVs.length === 0) {
			const generateEmptyMessage = () => {
				switch (state) {
					case "all":
						return "Il n’y a pas encore de CV dans la CVthèque.";
					case "toValidate":
						return "Il n’y a pas encore de CV à valider.";
					case "validated":
						return "Aucun CV n'a été validé.";
					case "editing":
						return "Il n’y a pas de CV en cours de modification par vos étudiants.";
					default:
						return "Tous vos étudiants ont importé leur CV.";
				}
			};
			return {
				emptyMessage: generateEmptyMessage(),
			} as { emptyMessage: string };
		}

		return {};
	};

	const handleTableSetCurrentFilters = (filters: FiltersType) => {
		dispatchEvent(setCurrentFilters(filters));
	};

	useEffect(() => {}, [currentFilters]);
	return (
		<>
			<Table
				isLoading={load || !CVs}
				className="mt-lg"
				headers={headers}
				filtersProps={{
					filters: currentFilters,
					setFilters: handleTableSetCurrentFilters,
				}}
				rows={CVs?.map((student) => {
					return {
						className: `${
							currentFilters.state === "without"
								? "!cursor-default !bg-transparent"
								: ""
						}`,
						onClick: () => {
							if (student.cv) {
								setShowCVModal(true);
								setStudentInModal(student);
							}
						},
						cells: cellsResolver(student),
					};
				})}
				{...getEmptyMessage(currentFilters.state)}
			/>

			<PaginationPrimary
				current={currentFilters.page}
				onChange={(newPage) => {
					dispatchEvent(
						setCurrentFilters({
							...currentFilters,
							page: newPage,
						}),
					);
				}}
				last={lastPage}
				className="py-md"
			/>

			{studentInModal && (
				<RefusalModal
					className="pb-md pt-md"
					cv={studentInModal.cv}
					show={showRefusalModal}
					onClose={() => {
						setShowRefusalModal(false);
					}}
					onSend={() => {
						success("Le CV est à modifier. L'étudiant sera notifié.");
						setShowRefusalModal(false);
					}}
				/>
			)}

			<CreateSelectionModal
				CVsListProps={{ selectedCVs, setSelectedCVs }}
				show={showShareCVModal}
				onClose={() => setShowShareCVModal(false)}
			/>

			{selectedCVs.length > 0 && (
				<ToastCustom>
					<div className="flex items-center gap-sm">
						<ButtonQuinary
							onClick={() => setSelectedCVs([])}
							icon={closeOutline}
						/>
						<p className="rounded-md bg-info-dark px-xsm py-xxsm text-xxsm text-white">
							{selectedCVs.length}
						</p>
						<p className="text-primary-700 text-nowrap text-xsm">
							CVs sélectionnés
						</p>
						{(currentFilters.state === "without" ||
							currentFilters.state === "editing") && (
							<ButtonPrimary
								onClick={() => {
									relaunchStudents();
								}}
								icon={emailOutline}
								label="Relancer"
							/>
						)}
						{(currentFilters.state === "all" ||
							currentFilters.state === "validated" ||
							currentFilters.state === "toValidate") && (
							<ButtonPrimary
								onClick={() => {
									downloadZip();
								}}
								label="Exporter"
							/>
						)}
						{(currentFilters.state === "validated" ||
							currentFilters.state === "all") && (
							<ButtonPrimary
								icon={paperPlaneOutline}
								label="Partager les CVs"
								onClick={() => {
									setShowShareCVModal(true);
								}}
							/>
						)}
					</div>
				</ToastCustom>
			)}
		</>
	);
}
