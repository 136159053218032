import { useAppSelector } from "@config/redux/hook";
import React from "react";

import { UsersFilters } from "@modules/usersFilters";
import { ResetUserFiltersButton } from "@modules/usersFilters/ui/ResetUserFiltersButton";
import { ToggleUserFilters } from "@modules/usersFilters/ui/ToggleUserFilters";
import { UserfilterSearchbar } from "@modules/usersFilters/ui/UserfilterSearchbar";

export function Filters() {
	const { currentFilters, showFilters } = useAppSelector(
		(state) => state.usersFilters,
	);

	return (
		<div className="flex flex-col gap-md">
			<div className="flex gap-sm">
				<UserfilterSearchbar />
				<ToggleUserFilters />
				<ResetUserFiltersButton />
			</div>
			{showFilters && (
				<UsersFilters
					filtersProps={{ filters: currentFilters, setFilters: () => {} }}
				/>
			)}
		</div>
	);
}
