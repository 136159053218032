import type { UserCompleteType } from "@/types/userTypes";
import { useAppSelector } from "@config/redux/hook";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import "../CVBoard.scss";

import { DAYS_BETWEEN_RELAUNCH } from "@constants/CvBoard";

import { diffDate } from "@tools/Dates";
import { resolveView } from "@tools/Users";

import { ScrollableTabGroup } from "@designSystem/molecules/TabGroup";
import { History, HistoryContext } from "@modules/logHistory/History";
import { setPageState } from "@modules/usersFilters/core/store/usersFilters.slice";

import { getAllCVs } from "../CVBoardAPI";
import { CVBoardContext } from "../index";

import { CVHeader } from "./CVsList/CVHeader";
import { SmallList } from "./CVsList/SmallList";
import { TableView } from "./CVsList/TableView";
import { CVModal } from "./CVsList/TableView/CVModal";
export function CVsList() {
	const dispatchEvent = useDispatch();

	const { currentFilters } = useAppSelector((state) => state.usersFilters);
	const { tabCounts, CVs, load, totalCount, structureView, allCVsFilters } =
		useContext(CVBoardContext);
	const [checkAllLoader, setCheckAllLoader] = useState<boolean>(false);
	const [selectedCVs, setSelectedCVs] = useState<UserCompleteType[]>([]);
	const [studentInModal, setStudentInModal] = useState<UserCompleteType | null>(
		null,
	);
	const [showCVModal, setShowCVModal] = useState<boolean>(false);
	const [showRefusalModal, setShowRefusalModal] = useState<boolean>(false);
	const [showShareCVModal, setShowShareCVModal] = useState<boolean>(false);
	const [updateHistory, setUpdateHistory] = useState<number>(0);
	const [fileUpload, setFileUpload] = useState({
		id: undefined,
		size: 0,
		path: "",
	});

	const formatLogDescriptionFunction = (logDescription: string) => {
		return logDescription.replace(
			"<p>",
			"<p style='font-size: 18px; color:#000000'>",
		);
	};

	const handleOnCheckCV = (student: UserCompleteType) => {
		const index = selectedCVs.findIndex(
			(cvToCheck: { id: string }) => cvToCheck.id === student.id,
		);

		if (index === -1) {
			setSelectedCVs([...selectedCVs, student]);
		} else {
			const newSelectedCVs = [...selectedCVs];
			newSelectedCVs.splice(index, 1);
			setSelectedCVs(newSelectedCVs);
		}
	};
	const checkStateExist = () => {
		const states = ["all", "validated", "toValidate", "editing", "without"];
		if (currentFilters?.state === undefined) return false;
		return states.includes(currentFilters.state);
	};

	const handleOnCheckAll = async () => {
		const filters = { ...allCVsFilters };
		filters.page = undefined;
		if (selectedCVs.length === totalCount) {
			setSelectedCVs([]);
			return;
		}

		setCheckAllLoader(true);

		if (currentFilters.state === "without") {
			filters.exists = { cv: false };
		} else {
			filters.exists = { cv: true };
		}

		const { data, responseLabel }: any = await getAllCVs(
			filters,
			resolveView(structureView, "spsPromotion"),
		);
		if (responseLabel === "success") {
			if (
				JSON.stringify(selectedCVs) ===
				JSON.stringify(data.collectionQuerySpsStudents.collection)
			) {
				setSelectedCVs([]);
			} else {
				if (currentFilters.state === "without") {
					const selectedCVsCopy =
						data.collectionQuerySpsStudents.collection.filter(
							(student: UserCompleteType) => {
								return (
									diffDate(new Date(student.cvRelaunchDate)) >
									DAYS_BETWEEN_RELAUNCH
								);
							},
						);
					setSelectedCVs(selectedCVsCopy);
				} else {
					setSelectedCVs(data.collectionQuerySpsStudents.collection);
				}
			}
			setCheckAllLoader(false);
		}
	};

	useEffect(() => {
		if (studentInModal && CVs.length > 0) {
			setStudentInModal(CVs[0]);
		}
	}, [CVs]);

	useEffect(() => {
		if (currentFilters?.state === undefined || !checkStateExist()) {
			dispatchEvent(setPageState({ page: 1, state: "all" }));
		}
	}, []);
	useEffect(() => {
		setSelectedCVs([]);
	}, [currentFilters.state]);

	useEffect(() => {}, [studentInModal]);

	return (
		<div className="col-span-4 md:col-span-8 lg:col-span-12">
			<ScrollableTabGroup
				tabs={[
					{
						label: `Tous les CV (${tabCounts.all})`,
						active: currentFilters.state === "all",
					},
					{
						label: `CV validés (${tabCounts.validated})`,
						active: currentFilters.state === "validated",
					},
					{
						label: `À valider (${tabCounts.toValidate})`,
						active: currentFilters.state === "toValidate",
					},
					{
						label: `En cours de modification (${tabCounts.editing})`,
						active: currentFilters.state === "editing",
					},
					{
						label: `En attente de CV (${tabCounts.without})`,
						active: currentFilters.state === "without",
					},
				]}
				onTabChange={(i) => {
					switch (i) {
						case 1:
							dispatchEvent(
								setPageState({
									...currentFilters,
									page: 1,
									state: "validated",
								}),
							);
							break;
						case 2:
							dispatchEvent(
								setPageState({
									...currentFilters,
									page: 1,
									state: "toValidate",
								}),
							);
							break;
						case 3:
							dispatchEvent(
								setPageState({
									...currentFilters,
									page: 1,
									state: "editing",
								}),
							);
							break;
						case 4:
							setShowCVModal(false);
							dispatchEvent(
								setPageState({
									...currentFilters,
									page: 1,
									state: "without",
								}),
							);
							break;
						default:
							dispatchEvent(
								setPageState({
									...currentFilters,
									page: 1,
									state: "all",
								}),
							);
					}
				}}
				className="mt-lg"
				isLoading={load}
			/>
			{studentInModal && showCVModal ? (
				<div className="mt-lg flex flex-wrap">
					<SmallList
						handleOnCheckCV={handleOnCheckCV}
						student={studentInModal}
						setStudent={setStudentInModal}
						setShowOffer={setShowCVModal}
						selectedCVs={selectedCVs}
						setSelectedCVs={setSelectedCVs}
						handleOnCheckAll={handleOnCheckAll}
						showShareCVModal={showShareCVModal}
						setShowShareCVModal={setShowShareCVModal}
						checkAllLoader={checkAllLoader}
					/>
					<div className="h-fit-content mt-md flex w-full flex-col justify-start gap-sm slg:mt-0 slg:w-9/12 l:flex-row xl:w-10/12">
						<CVModal student={studentInModal} />
						<div className="md:w-full l:w-4/12">
							<div className="sticky ml-0 slg:ml-sm l:top-5 l:ml-0 l:block l:w-full">
								<HistoryContext.Provider
									value={{ updateHistory, setUpdateHistory }}
								>
									<CVHeader
										fileUpload={fileUpload}
										setFileUpload={setFileUpload}
										student={studentInModal}
										setStudent={setStudentInModal}
									/>

									<History
										className="mb-4 ml-0 mt-sm rounded-2xl md:w-full lg:mb-0 lg:ml-0"
										currentStudent={studentInModal}
										filters={{
											action_list: [
												"submitCV",
												"modifyCV",
												"deleteCV",
												"cvToModify",
												"cvValidated",
												"cvReminder",
											],
											page: 1,
										}}
										formatLogDescriptionFunction={formatLogDescriptionFunction}
									/>
								</HistoryContext.Provider>
							</div>
						</div>
					</div>
				</div>
			) : (
				<TableView
					handleOnCheckCV={handleOnCheckCV}
					selectedCVs={selectedCVs}
					setSelectedCVs={setSelectedCVs}
					setStudentInModal={setStudentInModal}
					studentInModal={studentInModal}
					setShowCVModal={setShowCVModal}
					handleOnCheckAll={handleOnCheckAll}
					showRefusalModal={showRefusalModal}
					setShowRefusalModal={setShowRefusalModal}
					showShareCVModal={showShareCVModal}
					setShowShareCVModal={setShowShareCVModal}
					checkAllLoader={checkAllLoader}
				/>
			)}
		</div>
	);
}
