import { useAppSelector } from "@config/redux/hook";
import React from "react";
import { useDispatch } from "react-redux";

import { refreshOutline } from "@assets/Icons";

import { InlineButton } from "@designSystem/atoms/InlineButton";

import { resetUsersFilters } from "../core/store/usersFilters.slice";

export function ResetUserFiltersButton() {
	const dispatchEvent = useDispatch();
	const { filtersCounter, currentSearch, showFilters } = useAppSelector(
		(state) => state.usersFilters,
	);
	if (filtersCounter !== 0 && currentSearch === "" && showFilters) {
		return (
			<InlineButton
				label="Réinitialiser les filtres"
				icon={refreshOutline}
				onClick={() => {
					dispatchEvent(resetUsersFilters());
				}}
			/>
		);
	}

	return null;
}
